import React, { useState } from 'react';
import { DialogContainerComponent } from '../../layout/dialogContainer.component';
import PropTypes from 'prop-types';
import { Button, TextField, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { REVERT_LOCATION_TO_PREBUILT_LOCK_FAILURE, REVERT_LOCATION_TO_PREBUILT_LOCK_SUCCESS, revertLocationToPrebuiltLock } from '../location.actions';
import { handleToastMessage } from '../../layout/layout.actions';
import { withRouter } from 'react-router-dom';

function RevertToPrebuiltDialog({ selectedLocation, isOpen, onClose, onSubmit }) {
  const [errors, setErrors] = useState({});
  const setError = (field, error) => {
    setErrors(errors => ({ ...errors, [field]: error }));
    setIsSubmitDisabled(true);
  };
  const clearErrors = () => {
    setErrors({});
    setIsSubmitDisabled(false);
  };
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const handleConfirmLockSerialChange = e => {
    const newConfirmLockSerial = e.target.value;
    if (!newConfirmLockSerial) {
      setError('confirmLockSerial', 'Lock serial is required');
    } else if (newConfirmLockSerial !== selectedLocation.lockSerial) {
      setError('confirmLockSerial', 'Lock serial does not match');
    } else {
      clearErrors();
    }
  };
  return (
    <DialogContainerComponent
      isOpen={isOpen}
      isSubmitDisabled={isSubmitDisabled}
      onClose={onClose}
      onSubmit={onSubmit}
      title="Are you sure you want to revert to a prebuilt lock?"
    >
      <Typography variant="subtitle1">
        Please type in the lock serial number <strong>{selectedLocation.lockSerial}</strong> to confirm.
      </Typography>
      <TextField
        style={{marginTop: 10, marginBottom: 5}}
        label="Lock Serial Number"
        fullWidth
        onChange={handleConfirmLockSerialChange}
        variant="outlined"
        helperText={errors.confirmLockSerial ? errors.confirmLockSerial : undefined}
        error={Boolean(errors.confirmLockSerial)}
      />
    </DialogContainerComponent>
  );
}

RevertToPrebuilt.propTypes = {
  selectedLocation: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function RevertToPrebuilt({ className, disabled, history, selectedLocation }) {
  const [isOpen, setIsOpen] = useState(false);
  const dialog = {
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  };
  const dispatch = useDispatch();
  const revertToPrebuilt = async () => {
    const { type, response, messages } = await dispatch(revertLocationToPrebuiltLock(selectedLocation.id));
    if (type === REVERT_LOCATION_TO_PREBUILT_LOCK_SUCCESS) {
      dispatch(handleToastMessage('Location reverted to prebuilt lock'));
      history.push({
        pathname: `/locations/${response.location.id}`,
      });
    } else if (type === REVERT_LOCATION_TO_PREBUILT_LOCK_FAILURE) {
      let errorMessage = 'Failed to revert location';
      if (messages.length) {
        errorMessage = `${errorMessage}: ${messages.join(',')}`;
      }
      dispatch(handleToastMessage(errorMessage, true));
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        className={className}
        onClick={dialog.open}
        disabled={disabled}
      >
        Revert
      </Button>
      <RevertToPrebuiltDialog
        selectedLocation={selectedLocation}
        isOpen={dialog.isOpen}
        onClose={dialog.close}
        onSubmit={revertToPrebuilt}
      />
    </>
  );
}

RevertToPrebuilt.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.Boolean,
  history: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object.isRequired,
};

export default withRouter(RevertToPrebuilt);