import { isEmpty } from 'lodash';

import { CALL_API } from '../../middleware/api';

export const CREATE_LOCATION_REQUEST = 'CREATE_LOCATION_REQUEST';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAILURE = 'CREATE_LOCATION_FAILURE';

export const GET_LOCATION_REQUEST = 'GET_LOCATION_REQEUST';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';

export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';

export const UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCES';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';

export const REPLACE_LOCATION_LOCK_REQUEST = 'REPLACE_LOCATION_LOCK_REQUEST';
export const REPLACE_LOCATION_LOCK_SUCCESS = 'REPLACE_LOCATION_LOCK_SUCCESS';
export const REPLACE_LOCATION_LOCK_FAILURE = 'REPLACE_LOCATION_LOCK_FAILURE';

export const REVERT_LOCATION_TO_PREBUILT_LOCK_REQUEST = 'REVERT_LOCATION_TO_PREBUILT_LOCK_REQUEST';
export const REVERT_LOCATION_TO_PREBUILT_LOCK_SUCCESS = 'REVERT_LOCATION_TO_PREBUILT_LOCK_SUCCESS';
export const REVERT_LOCATION_TO_PREBUILT_LOCK_FAILURE = 'REVERT_LOCATION_TO_PREBUILT_LOCK_FAILURE';

export const DELETE_LOCATION_REQUEST = 'DELETE_LOCATION_REQUEST';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_FAILURE = 'DELETE_LOCATION_FAILURE';

export const MODERATE_REVIEW_REQUEST = 'MODERATE_REVIEW_REQUEST';
export const MODERATE_REVIEW_SUCCESS = 'MODERATE_REVIEW_SUCCESS';
export const MODERATE_REVIEW_FAILURE = 'MODERATE_REVIEW_FAILURE';

export const DECLINE_LOCATION_REQUEST = 'DECLINE_LOCATION_REQUEST';
export const DECLINE_LOCATION_SUCCESS = 'DECLINE_LOCATION_SUCCESS';
export const DECLINE_LOCATION_FAILURE = 'DECLINE_LOCATION_FAILURE';

export const UPDATE_LOCATION_PHOTOS_REQUEST = 'UPDATE_LOCATION_PHOTOS_REQUEST';
export const UPDATE_LOCATION_PHOTOS_SUCCESS = 'UPDATE_LOCATION_PHOTOS_SUCCESS';
export const UPDATE_LOCATION_PHOTOS_FAILURE = 'UPDATE_LOCATION_PHOTOS_FAILURE';

export const UPLOAD_LOCATION_PHOTO_REQUEST = 'UPLOAD_LOCATION_PHOTO_REQUEST';
export const UPLOAD_LOCATION_PHOTO_SUCCESS = 'UPLOAD_LOCATION_PHOTO_SUCCESS';
export const UPLOAD_LOCATION_PHOTO_FAILURE = 'UPLOAD_LOCATION_PHOTO_FAILURE';

export const ADD_LOCATION_PHOTO_REQUEST = 'ADD_LOCATION_PHOTO_REQUEST';
export const ADD_LOCATION_PHOTO_SUCCESS = 'ADD_LOCATION_PHOTO_SUCCESS';
export const ADD_LOCATION_PHOTO_FAILURE = 'ADD_LOCATION_PHOTO_FAILURE';

export const REMOVE_LOCATION_PHOTO_REQUEST = 'REMOVE_LOCATION_PHOTO_REQUEST';
export const REMOVE_LOCATION_PHOTO_SUCCESS = 'REMOVE_LOCATION_PHOTO_SUCCESS';
export const REMOVE_LOCATION_PHOTO_FAILURE = 'REMOVE_LOCATION_PHOTO_FAILURE';

export const CHANGE_LOCATION_PHOTO_STATUS_REQUEST = 'CHANGE_LOCATION_PHOTO_STATUS_REQUEST';
export const CHANGE_LOCATION_PHOTO_STATUS_SUCCESS = 'CHANGE_LOCATION_PHOTO_STATUS_SUCCESS';
export const CHANGE_LOCATION_PHOTO_STATUS_FAILURE = 'CHANGE_LOCATION_PHOTO_STATUS_FAILURE';

export const CREATE_REPLY_REQUEST = 'CREATE_REPLY_REQUEST';
export const CREATE_REPLY_SUCCESS = 'CREATE_REPLY_SUCCESS';
export const CREATE_REPLY_FAILURE = 'CREATE_REPLY_FAILURE';

export const UPDATE_REPLY_REQUEST = 'UPDATE_REPLY_REQUEST';
export const UPDATE_REPLY_SUCCESS = 'UPDATE_REPLY_SUCCESS';
export const UPDATE_REPLY_FAILURE = 'UPDATE_REPLY_FAILURE';

export const DELETE_REPLY_REQUEST = 'DELETE_REPLY_REQUEST';
export const DELETE_REPLY_SUCCESS = 'DELETE_REPLY_SUCCESS';
export const DELETE_REPLY_FAILURE = 'DELETE_REPLY_FAILURE';

export const SNOOZE_LOCATION_ALERTS_REQUEST = 'SNOOZE_LOCATION_ALERTS_REQUEST';
export const SNOOZE_LOCATION_ALERTS_SUCCESS = 'SNOOZE_LOCATION_ALERTS_SUCCESS';
export const SNOOZE_LOCATION_ALERTS_FAILURE = 'SNOOZE_LOCATION_ALERTS_FAILURE';

export const CANCEL_SNOOZE_LOCATION_ALERTS_REQUEST = 'CANCEL_SNOOZE_LOCATION_ALERTS_REQUEST';
export const CANCEL_SNOOZE_LOCATION_ALERTS_SUCCESS = 'CANCEL_SNOOZE_LOCATION_ALERTS_SUCCESS';
export const CANCEL_SNOOZE_LOCATION_ALERTS_FAILURE = 'CANCEL_SNOOZE_LOCATION_ALERTS_FAILURE';

export const UPDATE_SOFTWARE_REQUEST = 'UPDATE_SOFTWARE_REQUEST';
export const UPDATE_SOFTWARE_SUCCESS = 'UPDATE_SOFTWARE_SUCCESS';
export const UPDATE_SOFTWARE_FAILURE = 'UPDATE_SOFTWARE_FAILURE';

export const APPROVE_LOCATION_REQUEST = 'APPROVE_LOCATION_REQUEST';
export const APPROVE_LOCATION_SUCCESS = 'APPROVE_LOCATION_SUCCESS';
export const APPROVE_LOCATION_FAILURE = 'APPROVE_LOCATION_FAILURE';

export const HARDWARE_TEXT_FIELDS = [
  { name: 'podHardwareIdentifier', label: 'Pod Hardware Identifier' },
  { name: 'podHardwareRevision', label: 'Pod Hardware Revision' },
  { name: 'podSoftwareVersion', label: 'Pod Software Version' },
  { name: 'pcdSharedSecret', label: 'Hologram Device Key' },
  { name: 'podModemSerial', label: 'Pod Modem Serial #' },
  { name: 'podBuildDate', label: 'Pod Build Date' },
  { name: 'podHologramLinkID', label: 'Pod Hologram Link ID' },
  { name: 'podICCID', label: 'Pod ICC ID #' },
  { name: 'podIMEI', label: 'Pod IMEI #' },
  { name: 'podDeviceSerial', label: 'Pod Device Serial #' },
];

export const LOCATION_TEXT_FIELDS = [
  { name: 'latitude', label: 'Latitude' },
  { name: 'longitude', label: 'Longitude' },
  { name: 'addressOne', label: 'Address Line 1' },
  { name: 'addressTwo', label: 'Address Line 2 (optional)', optional: true },
  { name: 'city', label: 'City' },
  { name: 'state', label: 'State' },
  { name: 'zip', label: 'Zip Code' },
  { name: 'country', label: 'Country' },
  { name: 'name', label: 'Name' },
  { name: 'description', label: 'Description' },
];

const prepare = (location) => {
  if (isEmpty(location.addressTwo)) {
    delete location.addressTwo;
  }
  return location;
};

export const createLocation = (locationData) => {
  return {
    [CALL_API]: {
      types: [CREATE_LOCATION_REQUEST, CREATE_LOCATION_SUCCESS, CREATE_LOCATION_FAILURE],
      authenticated: true,
      endpoint: 'locations',
      method: 'POST',
      payload: prepare(locationData),
    },
  };
};

export const moderateReview = (locationId, reviewId, status) => {
  return {
    [CALL_API]: {
      types: [MODERATE_REVIEW_REQUEST, MODERATE_REVIEW_SUCCESS, MODERATE_REVIEW_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/moderate/${reviewId}`,
      method: 'POST',
      actionMetadata: {
        locationId,
        reviewId,
      },
      payload: {
        status,
      },
    },
  };
};

export const getLocation = (locationId) => {
  return {
    [CALL_API]: {
      types: [GET_LOCATION_REQUEST, GET_LOCATION_SUCCESS, GET_LOCATION_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}`,
      method: 'GET',
      actionMetadata: {
        locationId,
      },
    },
  };
};

export const setSelectedLocation = (location) => {
  return {
    type: SET_SELECTED_LOCATION,
    response: location,
  };
};

export const updateLocation = (locationId, locationData) => {
  return {
    [CALL_API]: {
      types: [UPDATE_LOCATION_REQUEST, UPDATE_LOCATION_SUCCESS, UPDATE_LOCATION_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}`,
      method: 'PUT',
      payload: prepare(locationData),
      actionMetadata: {
        locationId,
      },
    },
  };
};

export const replaceLocationLock = (locationId, lockSerial) => {
  return {
    [CALL_API]: {
      types: [REPLACE_LOCATION_LOCK_REQUEST, REPLACE_LOCATION_LOCK_SUCCESS, REPLACE_LOCATION_LOCK_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/replaceLock?lockSerial=${lockSerial}`,
      method: 'PUT',
      actionMetadata: {
        locationId,
      },
    },
  };
};

export const revertLocationToPrebuiltLock = (locationId) => {
  return {
    [CALL_API]: {
      types: [REVERT_LOCATION_TO_PREBUILT_LOCK_REQUEST, REVERT_LOCATION_TO_PREBUILT_LOCK_SUCCESS, REVERT_LOCATION_TO_PREBUILT_LOCK_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/revert`,
      method: 'POST',
      actionMetadata: {
        locationId,
      },
    },
  };
};

export const snoozeLocationAlerts = (locationId) => {
  return {
    [CALL_API]: {
      types: [SNOOZE_LOCATION_ALERTS_REQUEST, SNOOZE_LOCATION_ALERTS_SUCCESS, SNOOZE_LOCATION_ALERTS_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/snoozeAlerts`,
      method: 'PUT',
    },
  };
};

export const cancelSnoozeLocationAlerts = (locationId) => {
  return {
    [CALL_API]: {
      types: [CANCEL_SNOOZE_LOCATION_ALERTS_REQUEST, CANCEL_SNOOZE_LOCATION_ALERTS_SUCCESS, CANCEL_SNOOZE_LOCATION_ALERTS_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/snoozeAlerts`,
      method: 'DELETE',
    },
  };
};

export const deleteLocation = (location, deleteReason) => {
  return {
    [CALL_API]: {
      types: [DELETE_LOCATION_REQUEST, DELETE_LOCATION_SUCCESS, DELETE_LOCATION_FAILURE],
      authenticated: true,
      endpoint: `locations/${location.id}`,
      method: 'DELETE',
      payload: {
        deleteReason,
      },
      actionMetadata: {
        locationId: location.id,
        deleteReason,
      },
    },
  };
};

export const approveLocation = location => {
  return {
    [CALL_API]: {
      types: [APPROVE_LOCATION_REQUEST, APPROVE_LOCATION_SUCCESS, APPROVE_LOCATION_FAILURE],
      authenticated: true,
      endpoint: `locations/${location.id}/approve`,
      method: 'POST',
      actionMetadata: {
        locationId: location.id,
      },
    },
  };
};


export const rejectLocation = (location, rejectReason) => {
  return {
    [CALL_API]: {
      types: [DECLINE_LOCATION_REQUEST, DECLINE_LOCATION_SUCCESS, DECLINE_LOCATION_FAILURE],
      authenticated: true,
      endpoint: `locations/${location.id}/reject`,
      method: 'POST',
      payload: {
        rejectReason,
      },
      actionMetadata: {
        locationId: location.id,
        rejectReason,
      }
    }
  };
};

export const uploadLocationPhoto = (locationId, file) => {
  const payload = new FormData();
  payload.append('image', file, file.name);

  return {
    [CALL_API]: {
      types: [UPLOAD_LOCATION_PHOTO_REQUEST, UPLOAD_LOCATION_PHOTO_SUCCESS, UPLOAD_LOCATION_PHOTO_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/uploadPhoto`,
      method: 'POST',
      payload,
      actionMetadata: {
        locationId
      }
    }
  };
};

export const addLocationPhoto = (locationId, imageRef) => {
  return {
    [CALL_API]: {
      types: [ADD_LOCATION_PHOTO_REQUEST, ADD_LOCATION_PHOTO_SUCCESS, ADD_LOCATION_PHOTO_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/locationPhotos`,
      method: 'POST',
      payload: {
        imageRef,
      },
      actionMetadata: {
        locationId,
      },
    },
  };
};

export const updateLocationPhotosOrder = (locationId, locationPhotoIds) => {
  return {
    [CALL_API]: {
      types: [UPDATE_LOCATION_PHOTOS_REQUEST, UPDATE_LOCATION_PHOTOS_SUCCESS, UPDATE_LOCATION_PHOTOS_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/reorderPhotos`,
      method: 'POST',
      payload: {
        locationPhotoIds,
      },
      actionMetadata: {
        locationId,
      },
    },
  };
};

export const removeLocationPhoto = (locationId, locationPhotoId) => {
  return {
    [CALL_API]: {
      types: [REMOVE_LOCATION_PHOTO_REQUEST, REMOVE_LOCATION_PHOTO_SUCCESS, REMOVE_LOCATION_PHOTO_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/locationPhotos/${locationPhotoId}`,
      method: 'DELETE',
      actionMetadata: {
        locationId,
      },
    },
  };
};

export const changeLocationPhotoStatus = (locationId, locationPhotoId, status) => {
  return {
    [CALL_API]: {
      types: [CHANGE_LOCATION_PHOTO_STATUS_REQUEST, CHANGE_LOCATION_PHOTO_STATUS_SUCCESS, CHANGE_LOCATION_PHOTO_STATUS_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/locationPhotos/${locationPhotoId}/changeStatus`,
      method: 'POST',
      payload: {
        status,
      },
      actionMetadata: {
        locationId,
      },
    },
  };
};

export const createReply = (locationId, reviewData) => {
  return {
    [CALL_API]: {
      types: [CREATE_REPLY_REQUEST, CREATE_REPLY_SUCCESS, CREATE_REPLY_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/reviews`,
      method: 'POST',
      payload: reviewData,
      actionMetadata: {
        locationId,
      },
    },
  };
};

export const updateReply = (locationId, reviewId, reviewData) => {
  let newReview = { ...reviewData };
  delete newReview.id;
  delete newReview.status;
  delete newReview.user;
  delete newReview.createdDate;
  delete newReview.location;

  return {
    [CALL_API]: {
      types: [UPDATE_REPLY_REQUEST, UPDATE_REPLY_SUCCESS, UPDATE_REPLY_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/reviews/${reviewId}`,
      method: 'PUT',
      payload: newReview,
      actionMetadata: {
        locationId,
        reviewId,
      },
    },
  };
};

export const deleteReply = (locationId, parentReviewId, reviewId) => {
  return {
    [CALL_API]: {
      types: [DELETE_REPLY_REQUEST, DELETE_REPLY_SUCCESS, DELETE_REPLY_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/reviews/${reviewId}`,
      method: 'DELETE',
      actionMetadata: {
        locationId,
        parentReviewId,
        reviewId,
      },
    },
  };
};

export const updatePodSoftware = (locationId) => {
  return {
    [CALL_API]: {
      types: [UPDATE_SOFTWARE_REQUEST, UPDATE_SOFTWARE_SUCCESS, UPDATE_SOFTWARE_FAILURE],
      authenticated: true,
      endpoint: `locations/${locationId}/updatePodSoftware`,
      method: 'POST',
    },
  };
};
