import { CALL_API } from '../../middleware/api';
import { convertObjectToQueryString } from '../../utilities/urlUtils';

export const GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILURE = 'GET_LOCATIONS_FAILURE';
export const SEARCH_LOCATIONS_REQUEST = 'SEARCH_LOCATIONS_REQUEST';
export const SEARCH_LOCATIONS_SUCCESS = 'SEARCH_LOCATIONS_SUCCESS';
export const SEARCH_LOCATIONS_FAILURE = 'SEARCH_LOCATIONS_FAILURE';
export const SEARCH_NEARBY_LOCATIONS_REQUEST = 'SEARCH_NEARBY_LOCATIONS_REQUEST';
export const SEARCH_NEARBY_LOCATIONS_SUCCESS = 'SEARCH_NEARBY_LOCATIONS_SUCCESS';
export const SEARCH_NEARBY_LOCATIONS_FAILURE = 'SEARCH_NEARBY_LOCATIONS_FAILURE';
export const SET_SEARCH_FILTERS = 'SET_SEARCH_FILTERS';
export const CLEAR_SEARCH_FILTERS = 'CLEAR_SEARCH_FILTERS';
export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';
export const GET_LOCATION_LIFECYCLE_STATUS_REASONS = 'GET_LOCATION_LIFECYCLE_STATUS_REASONS';
export const GET_LOCATION_LIFECYCLE_STATUS_REASONS_SUCCESS = 'GET_LOCATION_LIFECYCLE_STATUS_REASONS_SUCCESS';
export const GET_LOCATION_LIFECYCLE_STATUS_REASONS_FAILURE = 'GET_LOCATION_LIFECYCLE_STATUS_REASONS_FAILURE';

export const getLocations = () => {
  return {
    [CALL_API]: {
      types: [GET_LOCATIONS_REQUEST, GET_LOCATIONS_SUCCESS, GET_LOCATIONS_FAILURE],
      authenticated: true,
      endpoint: 'locations',
      method: 'GET',
    },
  };
};

export const clearLocations = () => {
  return {
    type: CLEAR_LOCATIONS,
  };
};

export const setSearchFilters = (searchFilters) => {
  return {
    type: SET_SEARCH_FILTERS,
    searchFilters,
  };
};

export const clearSearchFilters = () => {
  return {
    type: CLEAR_SEARCH_FILTERS,
  };
};

export const getLocationsBySearchCriteria = searchCriterias => {
  const queryStringParams = convertObjectToQueryString(searchCriterias);
  return {
    [CALL_API]: {
      types: [SEARCH_LOCATIONS_REQUEST, SEARCH_LOCATIONS_SUCCESS, SEARCH_LOCATIONS_FAILURE],
      authenticated: true,
      endpoint: `locations/search?${queryStringParams}`,
      method: 'GET',
    },
  };
};

export const searchNearbyLocations = ({longitude, latitude, lifecycleStatuses = [], radius}) => {
  const query = new URLSearchParams();
  query.set('longitude', longitude);
  query.set('latitude', latitude);
  query.set('radius', radius);

  lifecycleStatuses.forEach(lifecycleStatus => query.append('lifecycleStatuses', lifecycleStatus));

  return {
    [CALL_API]: {
      types: [SEARCH_NEARBY_LOCATIONS_REQUEST, SEARCH_NEARBY_LOCATIONS_SUCCESS, SEARCH_NEARBY_LOCATIONS_FAILURE],
      authenticated: true,
      endpoint: `locations/search?${query}`,
      method: 'GET',
    },
  };
};

export const getLifecycleStatusReasons = () => {
  return {
    [CALL_API]: {
      types: [GET_LOCATION_LIFECYCLE_STATUS_REASONS, GET_LOCATION_LIFECYCLE_STATUS_REASONS_SUCCESS, GET_LOCATION_LIFECYCLE_STATUS_REASONS_FAILURE],
      authenticated: true,
      endpoint: 'locations/lifecycle-status-reasons',
      method: 'GET',
    },
  };
};
