import React from 'react';

/**
 * HOC to wrap a component to include a unique key
 * This is useful in controlling component remounts
 * For example, if you have an edit form you want to
 * remount (and reset all state) if you change which
 * entity you are editing, so do
 * withKey(props => props.entityToEdit.id)(EditComponent)
 */
export function withKey(keyFn) {
  return (Component) => {
    return (props) => {
      return <Component {...props} key={keyFn(props)} />;
    };
  };
}
