import { InfoWindow } from '@react-google-maps/api';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const MapLocationInfoWindow = ({ selectedMarker, onClose }) => {

  return (
    <InfoWindow
      position={selectedMarker}
      options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
      onCloseClick={onClose}
    >
      <div>
        {selectedMarker.contentData.map((content) => (
          <div key={content.url}>
            <Link to={content.url} target="_blank" rel="noopener noreferrer" >{content.label}</Link>
          </div>
        ))}
      </div>
    </InfoWindow>
  );
};

MapLocationInfoWindow.propTypes = {
  selectedMarker: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MapLocationInfoWindow;