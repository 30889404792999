import { combineReducers } from 'redux';

import hubspot from './modules/hubspot/hubspot.reducer';
import layout from './modules/layout/layout.reducer';
import location from './modules/location/location.reducer';
import locations from './modules/locations/locations.reducer';
import monitoring from './modules/monitoring/monitoring.reducer';
import promotionSlots from './modules/promotionSlots/promotionSlots.reducer';
import promotions from './modules/promotions/promotions.reducer';
import submissions from './modules/submissions/submissions.reducer';
import users from './modules/users/users.reducer';

const rootReducer = combineReducers({
  layout,
  location,
  locations,
  promotions,
  promotionSlots,
  users,
  submissions,
  monitoring,
  hubspot,
});

export default rootReducer;
