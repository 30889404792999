import { DialogContainerComponent } from '../../layout/dialogContainer.component';
import { LIFECYCLE_STATUS } from '../../../types/location.types';
import { SelectFormComponent } from '../../layout/singleSelectForm.component';
import { getLifecycleStatusReasons } from '../../locations/locations.actions';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export const LocationDeclineDialogComponent = ({ isOpen, onClose, onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState();
  const dispatch = useDispatch();
  const submissionsDeclineReasonLabels = useSelector((state) => {
    const lifecycleStatusReasons = state.locations.get('lifecycleStatusReasons');
    if (!lifecycleStatusReasons) {
      return null;
    }

    return lifecycleStatusReasons[LIFECYCLE_STATUS.REJECTED]
      .map(group => ({
        label: group.groupLabel,
        elements: group.reasons.map(reason => ({
          key: reason.reason,
          label: reason.label,
        })),
      }));
  });

  const handleSubmit = () => {
    onSubmit(selectedOption);
  };

  useEffect(() => {
    dispatch(getLifecycleStatusReasons());
  }, []);

  if (!submissionsDeclineReasonLabels) {
    return null;
  }

  return (
    <DialogContainerComponent isOpen={isOpen} isSubmitDisabled={!selectedOption} onClose={onClose} onSubmit={handleSubmit}
      title="Select reason for declining the location"
    >
      <SelectFormComponent inputValues={submissionsDeclineReasonLabels} onSelectChange={key => setSelectedOption(key)} selectedValue={selectedOption}/>
    </DialogContainerComponent>
  );
};

LocationDeclineDialogComponent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
