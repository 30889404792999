import { isNil } from 'lodash';
import moment from 'moment';

import { PCD_HARDWARE_TYPES } from '../types/location.types';
import {
  SIGNAL_QUALITY,
  SIGNAL_RANGE_PCDv1,
  SIGNAL_RANGE_PCDv2,
} from '../modules/monitoring/monitoring.actions';

export const isOnline = location => {
  if (!location) {
    return false;
  }
  if (!location.lastEvent) {
    return false;
  }
  const hoursSince = moment().diff(location.lastEvent, 'hours');
  return hoursSince < 1;
};

export const getLastEventText = location => {
  if (!location) {
    return 'Never Connected';
  }
  if (location.lastEvent) {
    return 'Last Event';
  }
  return 'Never Connected';
};

// PCDv1: Excellent, > -65, Good, -65 to -75, Fair -75 to -85, Poor < -85
// PCDv2: Excellent, > -85, Good, -85 to -95, Fair -95 to -105, Poor < -105

export const getSignalQuality = (signalStrength, location) => {
  if (isNil(signalStrength)) {
    return null;
  }

  let minExcellent, minGood, minFair;

  switch (location?.pcdHardwareType) {
    case PCD_HARDWARE_TYPES.PYCOM: 
      minExcellent = SIGNAL_RANGE_PCDv1.MIN_EXCELLENT;
      minGood = SIGNAL_RANGE_PCDv1.MIN_GOOD;
      minFair = SIGNAL_RANGE_PCDv1.MIN_FAIR;
      break;
    case PCD_HARDWARE_TYPES.PARTICLE: 
      minExcellent = SIGNAL_RANGE_PCDv2.MIN_EXCELLENT;
      minGood = SIGNAL_RANGE_PCDv2.MIN_GOOD;
      minFair = SIGNAL_RANGE_PCDv2.MIN_FAIR;
      break;
    default:
      return null;
  }

  if (signalStrength >= minExcellent) {
    return SIGNAL_QUALITY.EXCELLENT;
  } else if (signalStrength >= minGood) {
    return SIGNAL_QUALITY.GOOD;
  } else if (signalStrength >= minFair) {
    return SIGNAL_QUALITY.FAIR;
  } else {
    return SIGNAL_QUALITY.POOR;
  }
};

// Filter to 8 - 48 minutes for “Mom Occupancies”
export const isMomOccupancy = occupancy => {
  if (isNil(occupancy.occ_time)) {
    return null;
  }
  const occ_minutes = moment.duration(occupancy.occ_time, 'seconds').asMinutes();
  return occ_minutes >= 5 && occ_minutes <= 90;
};
