export const DEFAULT_SMARTPOD_PIN_CODES = ['2014', '0915', '9111', '8008'];
// Mamava would like to always set a default pin code for TTLocks (1234),
// so that cleaning and maintenance can use it and not be tracked for reporting.
export const DEFAULT_TTLOCK_PIN_CODES = [...DEFAULT_SMARTPOD_PIN_CODES, '1234'];

export const LOCATION_TYPES = {
  MAMAVA: 'MAMAVA',
  LOCATION: 'LOCATION',
};

export const LOCATION_FIELDS = {
  POD_HARDWARE_TYPE: 'podHardwareType',
};

export const HARDWARE_TYPES = {
  SMART_POD: 'SMART_POD',
  NO_TECH_POD: 'NO_TECH_POD',
  SMART_LOCK: 'SMART_LOCK',
  TTLOCK_POD: 'TTLOCK_POD',
  PCD_PAIRED_WITH_TTLOCK: 'PCD_PAIRED_WITH_TTLOCK',
  VAROOM_TTLOCK: 'VAROOM_TTLOCK',
  ZTU_POD: 'ZTU_POD',
};

export const OCCUPANCY_TYPES = {
  AVAILABLE: 'AVAILABLE',
  OCCUPIED: 'OCCUPIED',
};

export const APP_DISPLAY_LEVELS = {
  DISPLAY_ALWAYS: 'DISPLAY_ALWAYS',
  DISPLAY_BLUETOOTH_ONLY: 'DISPLAY_BLUETOOTH_ONLY',
  DISPLAY_NEVER: 'DISPLAY_NEVER',
};

export const LIFECYCLE_STATUS = {
  PREBUILT: 'PREBUILT',
  WAITING_TO_ACTIVATE: 'WAITING_TO_ACTIVATE',
  ACTIVE: 'ACTIVE',
  DECOMMISSIONED: 'DECOMMISSIONED',
  REJECTED: 'REJECTED',
  TEMPORARILY_DOWN: 'TEMPORARILY_DOWN',
  UNDER_REVIEW: 'UNDER_REVIEW',
  ACTIVATION_ON_HOLD: 'ACTIVATION_ON_HOLD',
  DEPRECATED: 'DEPRECATED',
  LOCATION_UNKNOWN: 'LOCATION_UNKNOWN',
};

export const PCD_HARDWARE_TYPES = {
  PYCOM: 'PYCOM',
  PARTICLE: 'PARTICLE',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
};

export const ORG_TYPE_OPTIONS = ['Business', 'Public Venue', 'Transportation', 'Healthcare', 'Education', 'Government', 'Military'];

export const SUBCATEGORY_OPTIONS = [
  'Business - Automotive + Aerospace + Defense',
  'Business - Cannabis',
  'Business - Construction + Real Estate + Furniture',
  'Business - CPG + Food + Beverage',
  'Business - Retail + Grocery',
  'Business - Hotel + Resort + Casino',
  'Business - Legal + Finance + Insurance',
  'Business - Logistics + Supply Chain',
  'Business - Oil + Gas + Energy',
  'Business - Pharma + Medical Devices + Labs',
  'Business - Restaurant + Food Service',
  'Business - Software + Information Technology + Media',
  'Business - Other',
  'Public Venue - Sports Stadium (College + Other)',
  'Public Venue - Sports Stadium (Professional)',
  'Public Venue - Convention Center',
  'Public Venue - Concert Arena',
  'Public Venue - Amusement Park',
  'Public Venue - Museum + Zoo',
  'Public Venue - Fair Grounds',
  'Public Venue - State Park',
  'Public Venue - Library',
  'Transportation - Airline + Airport',
  'Transportation - Train Station',
  'Transportation - Bus Station',
  'Healthcare - Hospital',
  'Healthcare - Dental',
  'Healthcare - Veterinary',
  'Healthcare - Medical Education',
  'Healthcare - Medical Office',
  'Education - Pre-K -12',
  'Education - Charter',
  'Education - College + University',
  'Government - Administration',
  'Government - Culture + Recreation',
  'Government - Education',
  'Government - Finance',
  'Government - Fire',
  'Government - General + Buildings',
  'Government - Human Resource',
  'Government - Judicial + Corrections',
  'Government - Police + Law Enforcement',
  'Government - Public Health',
  'Government - Public Works',
  'Government - GSA + Real Estate',
  'Government - Transportation',
  'Military - Air Force',
  'Military - Army',
  'Military - Coast Guard',
  'Military - Marines',
  'Military - Navy',
  'Military - VA',
  'Military - Federal',
  'Unknown',
];

export const SPACE_TYPE_DEFAULT_VALUES = ['LACTATION_ROOM', 'WELLNESS_ROOM', 'FIRSTAID_STATION', 'MAMAVA_POD', 'DRESSING_ROOM', 'LOUNGE', 'OTHER'];

export const ACCESS_DETAILS_DEFAULT_VALUES = ['TICKETED_VENUE', 'EMPLOYEES_ONLY', 'MULTI_USER_ROOM'];

export const LOCATION_IS_SCHEDULABLE = {
  ENABLED: true,
  DISABLED: false,
};

export const SUBMISSION_DELETE_REASON = {
  ROOM_NO_LONGER_EXIST: 'DELETED_ROOM_NO_LONGER_EXIST',
  BUILDING_IS_CLOSED: 'DELETED_BUILDING_IS_CLOSED',
  CREATED_IN_ERROR: 'DELETED_CREATED_IN_ERROR',
  OTHER: 'DELETED_OTHER',
};

export const COUNTRY_NAMES = {
  UNITED_STATES: 'United States',
  CANADA: 'Canada',
};